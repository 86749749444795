<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <h2 class="title-style pt-3">
      Check-In Records ✅
      <span
        v-if="(subscriptionCheck('trial'))"
        class="premium-container"
      >
        <v-icon class="premium-style">
          mdi-crown-circle
        </v-icon>
      </span>
    </h2>
    <p class="sub-title-style">
      Explore checkin records
    </p>
    <div v-if="subscriptionCheck('active')">
      <v-card
        color="#fff"
        class="project-list-card mt-0"
      >
        <div class="header">
          <div class="div-2">
            <v-badge
              v-if="filterCount !== 0"
              color="red"
              :content="filterCount"
              class="count-badge"
            />
            <v-btn
              color="#000"
              class="filter-btn"
              @click="showFilterDialog = true"
            >
              Filter
            </v-btn>
          </div>
          <div class="div-1">
            <download-excel
              :data="filteredCheckInRecords"
              :fields="jsonFields"
              worksheet="SwiftCheckinRecords"
              :name="getExportSheetName"
              :button_text="btnName"
            >
              <v-btn color="#37474F">
                Export
              </v-btn>
            </download-excel>
          </div>
          <div class="div-3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  dark
                  outlined
                  color="#37474F"
                  small
                  v-on="on"
                  @click="resetPagination()"
                >
                  <v-icon
                    small
                    dark
                  >
                    mdi-filter-off
                  </v-icon>
                </v-btn>
              </template>
              <span>Reset Filter</span>
            </v-tooltip>
          </div>
        </div>
        <v-card-title class="filter-card">
          <v-row>
            <v-col
              cols="12"
              lg="4"
              md="5"
              sm="12"
            >
              <v-text-field
                v-model="search"
                label="Search"
                :hint="isSearchAdded ? searchHint : ''"
                append-icon="mdi-magnify"
                persistent-hint
                outlined
                dense
                @keyup.enter="fetchSearchData"
                @keydown.esc="clearSearch"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <p
          v-if="!isSearchAdded"
          class="info-text"
        >
          <v-icon>mdi-account-hard-hat-outline</v-icon> Indicates Project Work <span class="division-style">|</span>
          <v-icon>mdi-account-wrench-outline</v-icon>Indicates Project Issue<span class="division-style">| </span>
          <v-icon>mdi-tablet-cellphone</v-icon> Indicates Kiosks<span class="division-style">| </span>
          <v-icon>mdi-swap-horizontal-bold</v-icon> Indicates Cost-Shift
        </p>
        <v-data-table
          :headers="headers"
          :items="filteredCheckInRecords"
          class="elevation-1 table-checkin"
          disable-sort
          :items-per-page="itemsPerPage"
          :footer-props="{
            'items-per-page-options': [10, 20, 30]
          }"
          :loading="updateProgress"
          light
          :server-items-length="totalFilteredRecords"
          mobile-breakpoint="100"
          :options.sync="options"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.app_user.first_name }} {{ item.app_user.last_name }}
                <v-tooltip
                  v-if="dailyWorkHrLimitReached(item)"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      color="#C62828"
                      v-on="on"
                    >
                      mdi-alert
                    </v-icon>
                  </template>
                  <span>Exceeded Daily Work Hour Limit</span>
                </v-tooltip>
              </td>
              <td>{{ item.app_user.company.name }}</td>
              <td class="my-type-style">
                <v-chip
                  outlined
                  class="type"
                  color="#37474F"
                  dark
                >
                  {{ item.type | staffToSiteManager }}
                  <span v-if="item.type === 'worker'">
                    <v-icon
                      v-if="item.work_details.type === 'project-work'"
                      small
                    >mdi-account-hard-hat-outline</v-icon>
                    <v-icon
                      v-if="item.work_details.type === 'project-issue'"
                      small
                    >mdi-account-wrench-outline</v-icon>
                  </span>
                </v-chip>
              </td>
              <td>{{ item.project.name }}</td>
              <td>
                <v-icon
                  v-if="item.checkin_method_type === 'cost-shift'"
                  small
                  color="#2E7D32"
                >
                  mdi-swap-horizontal-bold
                </v-icon>
                {{ item.checkin_time | convertToLocal }}
              </td>
              <td>
                <font v-if="item.checkin_kiosk !== null && item.checkin_method_type === 'cost-shift'">
                  <v-icon small>
                    mdi-tablet-cellphone
                  </v-icon>
                  N/A
                </font>
                <font v-if="item.checkin_kiosk !== null && item.checkin_method_type !== 'cost-shift'">
                  <v-icon small>
                    mdi-tablet-cellphone
                  </v-icon>
                  {{ item.checkin_kiosk.location_name }}
                </font>
                <font v-if="item.checkin_kiosk === null && item.checkin_method_type === 'cost-shift'">
                  N/A
                </font>
                <font v-if="item.checkin_kiosk === null && item.checkin_method_type !== 'cost-shift'">
                  {{ item.checkin_gate?.name }}
                </font>
              </td>
              <td>
                <font
                  v-if="item.checkout_time === null"
                  class="My-checkout-time"
                >
                  Onsite*
                </font>
                <font v-else>
                  {{ item.checkout_time | convertToLocal }}
                  <v-icon
                    v-if="item.checkout_method_type === 'cost-shift'"
                    color="#C62828"
                    small
                  >
                    mdi-swap-horizontal-bold
                  </v-icon>
                  <v-tooltip
                    v-if="item.manual_checkout === true"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        color="#C62828"
                        v-on="on"
                      >
                        mdi-account-arrow-right
                      </v-icon>
                    </template>
                    <span>Checkout Was Done Manually</span>
                  </v-tooltip>
                </font>
              </td>
              <td>
                <font v-if="item.checkout_kiosk !== null && item.checkout_method_type === 'cost-shift'">
                  <v-icon small>
                    mdi-tablet-cellphone
                  </v-icon>
                  N/A
                </font>
                <font v-if="item.checkout_kiosk !== null && item.checkout_method_type !== 'cost-shift'">
                  <v-icon small>
                    mdi-tablet-cellphone
                  </v-icon>
                  {{ item.checkout_kiosk.location_name }}
                </font>
                <font v-if="item.checkout_kiosk === null && item.checkout_method_type === 'cost-shift'">
                  N/A
                </font>
                <font v-if="item.checkout_kiosk === null && item.checkout_method_type !== 'cost-shift'">
                  {{ item.checkout_gate?.name }}
                </font>
                <font
                  v-if="item.manual_checkout === true || item.checkout_time === null || item.checkout_method_type === null"
                >
                  N/A
                </font>
              </td>
              <td>
                <v-col>
                  <v-btn
                    class="mt-n2 action-btn"
                    elevation="1"
                    fab
                    x-small
                    color="#37474F"
                    icon
                    @click="recordDetails(item.id)"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </v-col>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <centre-spinner :loading="loading" />
      <records-filter-dialog
        v-if="showFilterDialog"
        @filter-used="addFilter"
        @closed="showFilterDialog = false"
      />
    </div>
    <div v-else>
      <v-alert
        text
        prominent
        type="warning"
        color="#006883"
        border="left"
        class="alert-style"
      >
        Upgrade to Premium Version to view the CheckIns.
      </v-alert>
    </div>
  </v-container>
</template>
<script>
import moment from 'moment';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import RecordsFilterDialog from './RecordsFilterDialog.vue';
import JsonExcel from 'vue-json-excel';
import global from 'src/mixins/global';

export default {
  name: 'CheckInRecords',
  components: {
    'centre-spinner': spinner,
    'records-filter-dialog': RecordsFilterDialog,
    'download-excel': JsonExcel,
  },
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
    staffToSiteManager (val) {
      if (val === 'staff') {
        return 'Site Manager';
      }
      return val;
    },
  },
  mixins: [global],
  data () {
    return {
      search: null,
      updateProgress: false,
      options: {},
      headers: [
        { text: 'Name', align: 'start', value: 'app_user.first_name' },
        { text: 'Company', value: 'company.name' },
        { text: 'Type', value: 'type' },
        { text: 'Project', value: 'project.name' },
        { text: 'Check-In At', value: 'checkin_time' },
        { text: 'Check-In Gate', value: 'checkin_gate.name' },
        { text: 'Check-Out At', value: 'checkout_time' },
        { text: 'Check-Out Gate', value: 'checkout_gate.name' },
        { text: 'More', value: 'more' },
      ],
      loading: false,
      showFilterDialog: false,
      btnName: 'Export',
      jsonFields: {
        'First Name': 'app_user.first_name',
        'Last Name': 'app_user.last_name',
        Company: 'app_user.company.name',
        Project: 'project.name',
        'Check-In Gate': 'checkin_gate.name',
        Type: 'type',
        'Check-In Time': {
          field: 'checkin_time',
          callback: (value) => {
            return moment(value).local().format('Do MMMM YYYY hh:mm A');
          },
        },
        'Check-Out Gate': 'checkout_gate.name',
        'Check-Out Time': {
          field: 'checkout_time',
          callback: (value) => {
            if (value === null || value === '') {
              return '-';
            }
            return moment(value).local().format('Do MMMM YYYY hh:mm A');
          },
        },
        'Onsite Hours': 'total_hours',
      },
      page: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    getExportSheetName () {
      return 'swift_checkin_records_' + moment().format('DD-MM-YYYY-hh-mm') + '.xls';
    },
    paginatedFilteredRecords () {
      return this.$store.getters['records/getFilteredCheckInRecords'];
    },
    filteredCheckInRecords () {
      const records = this.paginatedFilteredRecords;
      return records.data;
    },
    totalFilteredRecords () {
        if (Object.keys(this.paginatedFilteredRecords).length > 0) {
          return this.paginatedFilteredRecords.total;
        }
        return 0;
    },
    isFilteredRecordsFetched () {
      if (this.paginatedFilteredRecords.total > 0) {
        return true;
      }
      return false;
    },
    recordsFilteringParams () {
      return this.$store.getters['records/getRecordsFilteringParams'];
    },
    isSearchAdded () {
      if (this.search === null || this.search === '') {
        return false;
      }
      return true;
    },
    searchHint () {
      return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
    },
    prePaginationNumber () {
      return this.$store.getters['records/getPrePaginationNumber'];
    },
    preItemsPerPage () {
      return this.$store.getters['records/getPreItemsPerPage'];
    },
    filterCount () {
      var count = 0;
      if (Object.values(this.recordsFilteringParams).length === 0) {
        return 0;
      } else {
        Object.values(this.recordsFilteringParams).filter((item) => {
        if (item !== null) {
          count++;
        }
      });
      return count;
      }
    },
  },
  watch: {
    options: {
      async handler () {
        await this.paginatedCall();
      },
      deep: true,
    },
  },
  async mounted () {
    this.loading = true;
    await this.checkPreRoute();
    this.loading = false;
  },
  methods: {
    recordDetails (recordId) {
      this.$router.push(`records/${recordId}`);
    },
    dailyWorkHrLimitReached (checkInDetails) {
      const checkInTime = new Date(checkInDetails.checkin_time).getTime();
      if (checkInDetails.checkout_time === null) {
        const currentTime = new Date().getTime();
        var diff = currentTime - checkInTime;
        var hrDiff = Math.floor(diff / 3.6e6);
        if (checkInDetails.app_user.work_hours <= hrDiff) {
          return true;
        }
        return false;
      } else {
        const checkOutTime = new Date(checkInDetails.checkout_time).getTime();
        var totalWorkHrs = checkOutTime - checkInTime;
        var hours = Math.floor(totalWorkHrs / 3.6e6);
        if (checkInDetails.app_user.work_hours <= hours) {
          return true;
        }
        return false;
      }
    },
    async clearSearch () {
      this.updateProgress = true;
      this.options.page = 1;
      this.pageStart = 0;
      this.search = null;
      await this.getFilteredData();
    },
    async resetPagination () {
      this.updateProgress = true;
      this.options.page = 1;
      this.options.itemsPerPage = 10;
      this.pageStart = 0;
      this.search = null;
      await this.$store.dispatch('records/clearRecordsFilteringParams');
      await this.getFilteredData();
    },
    async addFilter () {
      this.updateProgress = true;
      this.options.page = 1;
      this.pageStart = 0;
        await this.getFilteredData();
    },
    async fetchSearchData () {
      this.updateProgress = true;
      this.options.page = 1;
      this.pageStart = 0;
      await this.getFilteredData();
      this.updateProgress = false;
    },
    async getFilteredData () {
      this.updateProgress = true;
      await this.$store.dispatch('records/fetchFilteredRecordsList', {
        params: {
          fisrtName: this.recordsFilteringParams.firstName !== '' ? this.recordsFilteringParams.firstName : null,
          checkInTime: this.recordsFilteringParams.checkInTime,
          checkOutTime: this.recordsFilteringParams.checkOutTime,
          projectId: this.recordsFilteringParams.projectId,
          projectGateId: this.recordsFilteringParams.projectGateId,
          type: this.recordsFilteringParams.type,
          orderBy: 'desc',
          name: this.search ? this.search : null,
          limit: this.itemsPerPage,
          startPage: this.pageStart,
        },
      }).then(response => {
        this.updateProgress = false;
      }).catch(() => {
        this.updateProgress = false;
      });
    },
    async checkPreRoute () {
      const preRouteName = this.$router.history.current.meta.previousRoute.name;
      if ((Object.keys(this.paginatedFilteredRecords).length !== 0) && preRouteName === 'Record Details') {
        this.options.page = this.prePaginationNumber;
        this.options.itemsPerPage = this.preItemsPerPage;
      } else {
        await this.$store.dispatch('records/clearRecordsFilteringParams');
        await this.paginatedCall();
      }
    },
    async paginatedCall () {
    this.itemsPerPage = this.options.itemsPerPage;
    await this.$store.dispatch('records/savePrePaginationNumber', this.options.page);
    await this.$store.dispatch('records/savePreItemsPerPage', this.options.itemsPerPage);
    this.pageStart = this.options.page * this.itemsPerPage - this.itemsPerPage;
    this.getFilteredData();
    },
  },
};
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}

.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}

.v-data-table {
  background-color: #ECEFF1;
}

.title-style {
  color: #37474F;
  font-size: 36px;
  font-weight: 600;
}

.icon-style {
  color: #37474F;
  font-size: 45px;
}

.records-btn {
  margin-top: 10px;
}

.header {
  display: inline-block;
  width: 100%;
}

.div-1 {
  margin-top: 15px;
  float: right;
  margin-bottom: 10px;
}

.div-2 {
  margin-top: 15px;
  float: right;
  position: relative;
}

.div-3 {
  margin-top: 15px;
  float: right;
  margin-right: 15px;
}

.My-checkout-time {
  color: #2E7D32;
  font-weight: bold;
}

.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
  padding-bottom: 10px;
}

.division-style {
  margin-left: 5px;
  margin-right: 5px;
}

.my-type-style {
  text-transform: capitalize;
}

.more-link {
  cursor: pointer;
}

.premium-style {
  font-size: 25px;
  color: #006883 !important;
  font-weight: bold;
}

.premium-container {
  border-radius: 50%;
}

.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}

.table-checkin {
  border-radius: 0px !important;
  background: #fff !important;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}

.table-checkin tbody tr:nth-of-type(even) {
  background: #fff !important;
}

.table-checkin tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}

.action-btn {
  background: #fff !important;
}

.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
  margin-top: -7px;
}

.table-top-border {
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}

.info-text {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.sub-title-style {
  color: #aaa;
}

.project-list-card {
  box-shadow: none !important;
}

.text-left {
  text-align: right;
}

.text-left>>>ul {
  justify-content: flex-end !important;
}

.text-left>>>ul li {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.custom-pagination >>> .v-pagination__item {
  box-shadow: none !important;
  border : 1px solid #ccc;
}

.custom-pagination >>> .v-pagination__item--active {
  box-shadow: none !important;
}
.custom-pagination >>> .v-pagination__navigation {
  box-shadow: none !important;
}
.count-badge{
z-index: 10;
position: absolute;
right:28px;
top:8px;
}
</style>
