import { render, staticRenderFns } from "./CheckInRecords.vue?vue&type=template&id=1cfb0672&scoped=true"
import script from "./CheckInRecords.vue?vue&type=script&lang=js"
export * from "./CheckInRecords.vue?vue&type=script&lang=js"
import style0 from "./CheckInRecords.vue?vue&type=style&index=0&id=1cfb0672&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cfb0672",
  null
  
)

export default component.exports