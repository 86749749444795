<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
    persistent
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-calendar-clock
        </v-icon> Filter Records
      </h2>
      <v-form>
        <v-container fluid>
          <v-card-text class="text-body-1 text-center">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="filteringData.firstName"
                  label="First Name"
                  class="field-style"
                  hint="App user's first name"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="filteringData.type"
                  :items="possibleTypes"
                  item-text="name"
                  class="field-style"
                  item-value="id"
                  label="Type"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-datetime-picker
                  v-model="filteringData.checkInTime"
                  label="From"
                  date-format="yyyy-MM-dd"
                  time-format="HH:mm"
                  class="field-style"
                  :text-field-props="{
                    suffix: userTimeZone,
                    class: 'custom-label-color',
                    outlined: true,
                    dense: true,
                  }"
                  :date-picker-props="{
                    headerColor: '#37474F',
                    max: maxStartDate
                  }"
                  :time-picker-props="{
                    headerColor: '#37474F'
                  }"
                >
                  <template v-slot:dateIcon>
                    <v-icon
                      large
                      color="#37474F"
                    >
                      mdi-calendar
                    </v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon
                      large
                      color="#37474F"
                    >
                      mdi-alarm
                    </v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-datetime-picker
                  v-model="filteringData.checkOutTime"
                  label="To"
                  class="field-style"
                  :text-field-props="{
                    persistentHint: true,
                    suffix: userTimeZone,
                    outlined: true,
                    dense: true,
                  }"
                  :date-picker-props="{
                    headerColor: '#37474F',
                    min: minEndDate,
                    max: maxStartDate
                  }"
                  :time-picker-props="{
                    headerColor: '#37474F'
                  }"
                >
                  <template v-slot:dateIcon>
                    <v-icon
                      large
                      color="#37474F"
                    >
                      mdi-calendar
                    </v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon
                      large
                      color="#37474F"
                    >
                      mdi-alarm
                    </v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-autocomplete
                  v-model="filteringData.projectId"
                  :items="projectsList"
                  class="field-style"
                  item-text="name"
                  item-value="id"
                  label="Project"
                  outlined
                  dense
                  @input="getFilteredProjectGates()"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-autocomplete
                  v-model="filteringData.projectGateId"
                  :items="filteredProjectGates"
                  class="field-style"
                  item-text="name"
                  :disabled="!isProjectSelected"
                  item-value="id"
                  label="Project Gate"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              depressed
              default
              @click="submit()"
            >
              Filter
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              depressed
              default
              @click="cancel()"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
  import moment from 'moment-timezone';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'RecordFilterDialog',
    components: {
      'centre-spinner': spinner,
    },
    data: () => ({
      filteringData: {
        firstName: null,
        lastName: null,
        checkInTime: null,
        checkOutTime: null,
        projectId: null,
        projectGateId: null,
        type: null,
        max: null,
      },
      possibleTypes: [
        {
          name: 'Worker',
          id: 'worker',
        },
        {
          name: 'Contractor',
          id: 'contractor',
        },
        {
          name: 'Visitor',
          id: 'visitor',
        },
        {
          name: 'Site Manager',
          id: 'staff',
        },
      ],
      showModal: true,
      maxStartDate: moment().format('YYYY-MM-DD'),
      loading: false,
    }),

    computed: {
      projectsList () {
        return this.$store.getters['projects/getProjectsList'];
      },
      filteredProjectGates () {
        return this.$store.getters['projectgates/getFilteredProjectGates'];
      },
      recordsFilteringParams () {
        return this.$store.getters['records/getRecordsFilteringParams'];
      },
      isAnyFilterUsed () {
      if (Object.keys(this.recordsFilteringParams).length !== 0) {
        return true;
      } else {
        return false;
      }
      },
      minEndDate () {
        return moment(this.filteringData.checkInTime).format('YYYY-MM-DD');
      },
      userTimeZone () {
        var Zone = moment.tz.guess();
        var timezone = moment.tz(Zone).zoneAbbr();
        return timezone;
      },
      isProjectSelected () {
        if (this.filteringData.projectId === null || this.filteringData.projectId === '') {
          return false;
        }
        return true;
      },
    },

    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.loading = true;
      await this.getProjectsList();
      await this.checkAnyFilterUsed();
      this.loading = false;
    },
    methods: {
      async getProjectsList () {
        await this.$store.dispatch('projects/fetchProjectsList');
      },
      async getFilteredProjectGates () {
        await this.$store.dispatch('projectgates/fetchFilteredProjectGates', {
          params: {
            projectId: this.filteringData.projectId,
          },
        });
      },
      cancel () {
        this.showModal = false;
      },
      async submit () {
        this.$store.dispatch('records/storeRecordsFilteringParams', {
          filteringData: this.filteringData,
        });
        this.$emit('filter-used');
        this.showModal = false;
      },
      checkAnyFilterUsed () {
if (this.isAnyFilterUsed) {
  this.filteringData.firstName = this.recordsFilteringParams.firstName;
  this.filteringData.checkInTime = this.recordsFilteringParams.checkInTime;
  this.filteringData.checkOutTime = this.recordsFilteringParams.checkOutTime;
  this.filteringData.projectId = this.recordsFilteringParams.projectId;
  this.filteringData.projectGateId = this.recordsFilteringParams.projectGateId;
  this.filteringData.type = this.recordsFilteringParams.type;
}
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
}
.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}
.field-style {
  margin-top: -10px;
}
</style>
